// alert.js
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// auth.js
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";

// data.js
export const REFRESH_DATA = "REFRESH_DATA";
export const LOAD_DATA = "LOAD_DATA";
export const ADD_DATA = "ADD_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const EXPORT_DATA = "EXPORT_DATA";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const CRUD_FAIL = "CRUD_FAIL";

// dashboard
export const LOAD_DASHBOARD_SERVICES = "LOAD_DASHBOARD_SERVICES";
export const LOAD_DASHBOARD_MEKANIK = "LOAD_DASHBOARD_MEKANIK";

// master.js
export const LOAD_USER = "LOAD_USER";
export const LOAD_ROLE = "LOAD_ROLE";
export const LOAD_MODULE = "LOAD_MODULE";
export const LOAD_WAREHOUSE = "LOAD_WAREHOUSE";
export const LOAD_CHECKLIST = "LOAD_CHECKLIST";
export const LOAD_SPK = "LOAD_SPK";
export const LOAD_SPKID = "LOAD_SPKID";
export const LOAD_WORK_ORDER = "LOAD_WORK_ORDER";
export const LOAD_WORK_ORDERID = "LOAD_WORK_ORDERID";
export const LOAD_PURCHASE_REQUEST = "LOAD_PURCHASE_REQUEST";
export const LOAD_VEHICLE = "LOAD_VEHICLE";
export const LOAD_PACKAGE = "LOAD_PACKAGE";
export const LOAD_VENDOR = "LOAD_VENDOR";
export const LOAD_MEKANIK = "LOAD_MEKANIK";
export const LOAD_DRIVER = "LOAD_DRIVER";
export const LOAD_SERVICE = "LOAD_SERVICE";
export const LOAD_ITEM = "LOAD_ITEM";
export const LOAD_KELUHAN = "LOAD_KELUHAN";
export const LOAD_STOCK_ITEM = "LOAD_STOCK_ITEM";
export const LOAD_REPORT_USED = "LOAD_REPORT_USED";
export const LOAD_REPORTKM = "LOAD_REPORTKM";
export const LOAD_REPORTINSENTIVE = "LOAD_REPORTINSENTIVE";
export const LOAD_REPORTMOBIL = "LOAD_REPORTMOBIL";
export const LOAD_PERMINTAAN_PR = "LOAD_PERMINTAAN_PR";
export const LOAD_PERMINTAAN_ITR = "LOAD_PERMINTAAN_ITR";
export const LOAD_BENGKEL = "LOAD_BENGKEL";
export const LOAD_QTYFORITR = "LOAD_QTYFORITR";
export const LOAD_ITR = "LOAD_ITR";

// sams
export const CHECKLIST_STATUS = "CHECKLIST_STATUS";
export const SPK_STATUS = "SPK_STATUS";
export const WO_STATUS = "WO_STATUS";
export const ITR_STATUS = "ITR_STATUS";
export const PR_STATUS = "PR_STATUS";

// ice.js
export const LOAD_FLEETCATEGORY = "LOAD_FLEETCATEGORY";
export const LOAD_FLEETTYPE = "LOAD_FLEETTYPE";
export const LOAD_CATEGORY = "LOAD_CATEGORY";
export const LOAD_INDUSTRY = "LOAD_INDUSTRY";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const LOAD_ADDRESS = "LOAD_ADDRESS";
export const LOAD_PRODUCT = "LOAD_PRODUCT";
export const LOAD_POOL = "LOAD_POOL";
export const LOAD_ITEMTYPE = "LOAD_ITEMTYPE";
export const LOAD_ROUTE = "LOAD_ROUTE";
export const GET_PRICE = "GET_PRICE";

export const ORDER_STATUS = "ORDER_STATUS";
export const SHIPMENT_STATUS = "SHIPMENT_STATUS";