import {
  LOAD_MODULE, LOAD_ROLE, LOAD_USER,
  LOAD_FLEETTYPE, LOAD_FLEETCATEGORY,
  LOAD_CATEGORY, LOAD_INDUSTRY,
  LOAD_CUSTOMER, LOAD_ADDRESS, ORDER_STATUS,
  LOAD_PRODUCT, LOAD_POOL, LOAD_ITEMTYPE, LOAD_ROUTE, GET_PRICE,
  LOAD_WAREHOUSE, LOAD_CHECKLIST,
  LOAD_VEHICLE,
  LOAD_PACKAGE, LOAD_MEKANIK, LOAD_DRIVER, LOAD_VENDOR, LOAD_SERVICE, LOAD_ITEM,
  LOAD_KELUHAN, LOAD_SPK, LOAD_SPKID, CHECKLIST_STATUS, SPK_STATUS, WO_STATUS, ITR_STATUS, PR_STATUS,
  LOAD_WORK_ORDER, LOAD_WORK_ORDERID, LOAD_STOCK_ITEM, LOAD_PURCHASE_REQUEST, LOAD_REPORTKM, LOAD_REPORT_USED, LOAD_REPORTMOBIL,
  LOAD_PERMINTAAN_PR, LOAD_PERMINTAAN_ITR, LOAD_BENGKEL, LOAD_REPORTINSENTIVE,
  LOAD_QTYFORITR, LOAD_ITR, LOAD_DASHBOARD_SERVICES, LOAD_DASHBOARD_MEKANIK
} from "../actions/types";

const initialState = {
  user: null,
  role: null,
  module: null,
  warehouse: null,
  checklist: null,
  spk: null,
  spk_id: null,
  workorder: null,
  workorder_id: null,
  purchase: null,
  vehicle: null,
  packages: null,
  vendor: null,
  mekanik: null,
  driver: null,
  service: null,
  item: null,
  keluhan: null,
  stock_item: null,
  itr: null,
  pr: null,
  reportKM: null,
  reportInsentive: null,
  report_used: null,
  reportMobil: null,
  permintaan_pr: null,
  permintaan_itr: null,
  bengkel: null,
  qtyITR: null,
  itr: null,

  dash_services: null,
  dash_mekanik: null,

  fleetCategory: null,
  fleetType: null,
  category: null,
  industry: null,
  customer: null,
  address: null,
  product: null,
  pool: null,
  itemType: null,
  route: null,
  price: 0,

  order: null,
};

export default function master(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // DASHBOARD
    case LOAD_DASHBOARD_SERVICES: return { ...state, dash_services: payload.data };
    case LOAD_DASHBOARD_MEKANIK: return { ...state, dash_mekanik: payload.data };

    // WMS
    case LOAD_USER: return { ...state, user: payload.data };
    case LOAD_ROLE: return { ...state, role: payload.data };
    case LOAD_MODULE: return { ...state, module: payload.data };
    case LOAD_WAREHOUSE: return { ...state, warehouse: payload.data };
    case LOAD_CHECKLIST: return { ...state, checklist: payload.data };
    case LOAD_SPK: return { ...state, spk: payload.data };
    case LOAD_WORK_ORDER: return { ...state, workorder: payload.data };
    case LOAD_WORK_ORDERID: return { ...state, workorder_id: payload.data };
    case LOAD_PURCHASE_REQUEST: return { ...state, purchase: payload.data };
    case LOAD_SPKID: return { ...state, spk_id: payload.data };
    case LOAD_VEHICLE: return { ...state, vehicle: payload.data };
    case LOAD_PACKAGE: return { ...state, packages: payload.data };
    case LOAD_MEKANIK: return { ...state, mekanik: payload.data };
    case LOAD_DRIVER: return { ...state, driver: payload.data };
    case LOAD_VENDOR: return { ...state, vendor: payload.data };
    case LOAD_SERVICE: return { ...state, service: payload.data };
    case LOAD_KELUHAN: return { ...state, keluhan: payload.data };
    case LOAD_STOCK_ITEM: return { ...state, stock_item: payload.data };
    case CHECKLIST_STATUS: return { ...state, checklist: payload.data };
    case SPK_STATUS: return { ...state, spk: payload.data };
    case WO_STATUS: return { ...state, wo: payload.data };
    case ITR_STATUS: return { ...state, itr: payload.data };
    case PR_STATUS: return { ...state, pr: payload.data };
    case LOAD_REPORTMOBIL: return { ...state, reportMobil: payload.data };
    case LOAD_REPORT_USED: return { ...state, report_used: payload.data };
    case LOAD_REPORTKM: return { ...state, reportKM: payload.data };
    case LOAD_REPORTINSENTIVE: return { ...state, reportInsentive: payload.data };
    case LOAD_PERMINTAAN_PR: return { ...state, permintaan_pr: payload.data };
    case LOAD_PERMINTAAN_ITR: return { ...state, permintaan_itr: payload.data };
    case LOAD_BENGKEL: return { ...state, bengkel: payload.data };
    case LOAD_QTYFORITR: return { ...state, qtyITR: payload.data };
    case LOAD_ITR: return { ...state, itr: payload.data };
    case LOAD_ITEM: return { ...state, item: payload.data }

    default: return state;
  }
}
